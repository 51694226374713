// VALUES

@vtiny : 8px;
@tiny   : 10px;
@small  : 12px;
@medium : 20px;
@large  : 30px;
@medium-20-0 : @medium 0;

@max : 100%;

// COLORS 
@c-var           : color;
@primary-color   : #00AD4C;
@secondary-color : #014050;
@tertiary-color  : #4A7E9F;
@white           : #ffffff;
@black           : #000000;
@b-black         : #014050;
@gray            : #cccccc;
@green           : #52C41A;
@peach           : #F7CB73;
@red             : #EA3B3B;
@blue            : #89D4F0;
@body            : #F3F3F7;
@border          : #E7E9EF;
@cyan            : #3DB5C5;
@dark-blue       : #172C44;
@dark-magenta    : #462144;
@moderate-red    : #B25E6E;
@soft-red        : #F36750;
@bright-orange   : #FABC47;
@very-dark-gray  : #363636;
@dark-gray       : #696969;
@grayish-cyan    : #F5FBFC;
@light-gray      : #F2F2F2;

// WIDTH 
@max-100  : @max;
@width-300: 300px;

// SHADOW
@s-var : box-shadow;
@shadow: 0px 2px 16px 1px rgba(0, 0, 0, 0.1);

// SCREEN
  // -- max
@max400: ~"(max-width: 400px)";
@max426: ~"(max-width: 426px)";
@max768: ~"(max-width: 768px)";
@max1280: ~"(max-width: 1280px)";
@max1920: ~"(max-width: 1920px)";

  // -- min
@min768: ~"(min-width: 768px)";

// Font Sizes
@min24px: normal normal normal 14px/24px Poppins;
@med24px: normal normal medium 14px/24px Poppins;
@normal24px: normal normal normal 16px/24px Poppins;
@normal24px600: normal normal 600 16px/24px Poppins;
@max32px: normal normal 600 24px/32px Poppins;
@max46px: normal normal 600 30px/46px Poppins;