@import '../../../views-dir.less';

.header-class {
  padding: 20px 20px;
}

.header-avatar {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #999999 !important;
  cursor: pointer;
}

.icon-dropdown {
  color: gray;
  font-size: 18px !important;
  cursor: pointer;
}

.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 6px !important;
}
@primary-color: #00AD4C;@border-radius-base: 5px;@border-color-base: #49CB5C;