// WIDTH 
.max-width {
    width: @max-100;
}

.width-300 {
    width: @width-300;
}

.mobile-header {
    max-width: 270px;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

// BORDER RADIUS 
.b-radius-20 {
    border-radius: @medium;
}

.b-radius-10 {
    border-radius: @tiny;
}

// PROFILE IMG 
.profile-img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #e7e7e7;
    color: @white;

    font-size: 24px;
    text-align: center;
}

// DISPLAY 
.d-block {
    display: block;
}
.visible-xs {
    display: none !important;
}
.d-i-block {
    display: inline-block;
}

// HEIGHT 
.max-height {
    height: @max-100;
}

.max-height-47 {
    height: 47%;
}

.icon-left {
    .ant-picker-suffix {
        position: absolute;
        margin: 0;
        left: 0;
        color: rgba(0, 0, 0, 0.85);
    }
    input {
        padding-left: 18px !important;
    }
}

.ant-form-item-has-error {
    .icon-left {
        .ant-picker-suffix {
            color: #ff4d4f;
        }
    }
}

.custom-tooltip {
    background: white;
    color: inherit;
    font-size: inherit;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 2px;
    padding: 5px 9px;
}

.icon-container{
    border-radius: 5px;
    padding: 8px;
    background-color: white;
}

// DYNAMIC INFO MODAL 
.dynamic-info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2{
        margin: 0;
        font-weight: 600;
    }
    p{
        text-align: center;
        margin: @medium-20-0;
    }
}

// BACKGROUND
.bg-primary {
    background-color: @primary-color;
}

.bg-secondary {
    background-color: @secondary-color;
}

.bg-peach {
    background-color: @peach;
}

.bg-green {
    background-color: @green;
}

.bg-white{
    background-color: @white;
}

// SHADOW 
.@{s-var} {
    @{s-var}: @shadow;
}

// PADDING
.padding-s {
    padding: @tiny;
}
.padding-s-v {
    padding: @tiny 0;
}
.pad-bottom-s {
    padding-bottom: @tiny;
}
.padding-m {
    padding: @medium;
}

.padding-m-v {
    padding: @medium 0;
}
.pad-bottom-s-s{
    padding-bottom: @vtiny
}

// MARGIN
.r-margin {
    margin: 0 !important;
}
.margin-top-s {
    margin-top: @tiny;
}
.margin-bottom-s {
    margin-bottom: @tiny;
}
.margin-top-m {
    margin-top: @medium;
}
.margin-top-l {
    margin-top: @large;
}
.margin-bottom-m {
    margin-bottom: @medium;
}
.margin-m-v {
    margin: @medium-20-0;
}

//MARGIN RIGHT 
.margin-right-s{
    margin-right: @tiny;
}
.margin-auto-r {
    margin-right: auto;
}

// MARGIN LEFT 
.space-left-s-xs {
    margin-left: @tiny;
}
.margin-auto-l {
    margin-left: auto;
}

// GAP/GRID-GAP
.gap-flex {
    gap: @medium !important;
}
.gap-10 {
    gap: 10px !important
}
.gap-20 {
    gap: 20px !important
}

// FONT
.font-11 {
    font-size: 11px;
}
.font-12{
    font-size: 12px;
}
.font-13{
    font-size: 13px;
}
.font-14{
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
}
.font-18 {
    font-size: 18px;
}
.font-20 {
    font-size: 20px;
}
.font-26 {
    font-size: 30px;
}
.font-30 {
    font-size: 30px;
}
.font-60 {
    font-size: 60px;
}
.fw-600 {
    font-weight: 600;
}
.fw-400 {
    font-weight: 450;
}
// COLOR
.text-primary {
    @{c-var}: @primary-color;
}
.text-secondary {
    color: @secondary-color;
}
.text-tertiary {
    color: @tertiary-color;
}
.text-blue {
    color: @blue;
}
.text-white {
    color: @white;
}
.text-error {
    color: @red;
}

.link-secondary,
.text-gray {
    color: darken(@gray, 20%);
}
.link-primary:hover{
    @{c-var}: @primary-color;
}

.link-secondary:hover {
    @{c-var}: @secondary-color;
}

// TEXT DECORATION/TRANSFORM
.text-underline {
    text-decoration: underline;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-capitalize {
    text-transform: capitalize;
}

// ALIGNMENT 
.text-center{
    text-align: center;
}
.text-right{
    text-align: right;
}
.text-justify{
    text-align: justify;
}
// CURSOR 
.c-pointer {
    cursor: pointer;
}

.c-disabled {
    cursor: not-allowed;
}

.tableClass {
    th {
       color: @primary-color;
    }
 }
 .tableClassOne{
    & thead > tr > th {
      color: 'pink';
    }
 }

 @media @max768 {
     .visible-xs {
         display: block !important;
     }

     .max-width-xs {
         width: 100%;
     }

     .d-header,
     .hidden-xs {
         display: none;
     }

    .mobile-add {
        position: fixed;
        right: 14px;
        bottom: 14px;
        width: 50px !important;
        height: 50px !important;
        z-index: 1;
    }

    .space-top-m-xs {
        margin-top: 20px
    }

    .space-left-s-xs {
        margin-left: 0;
    }

    .action-container {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        * {
            width: 100%;
            margin: 0 !important;
            padding: 0;
        }
    }
 }