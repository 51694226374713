.ant-menu:not(.ant-menu-horizontal) {
    .ant-menu-item-selected {
        font-weight: 600;
    }
}

.ant-input-affix-wrapper {
    padding: 8px 11px;
}

.ant-picker-active-bar {
    background-color: @cyan !important;
}

.ant-picker-cell-today>div::before {
    border: 1px solid @cyan;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background: @cyan;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: @cyan;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: rgba(#3DB5C5, .25) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
    background: rgba(#3DB5C5, .25);
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: rgba(#3DB5C5, .25) !important;
}

.ant-select-selection-selected-value {
    background: rgba(#3DB5C5, .25);
}

.ant-select-item-option-active {
    background: rgba(#3DB5C5, .25) !important;
    font-weight: 600;

    &:hover {
        background: rgba(#3DB5C5, .1);
    }
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 1px solid @cyan !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: @cyan !important;
}

.ant-picker-today-btn {
    color: @cyan !important;

    &:hover {
        color: @cyan;
    }
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background: rgba(#3DB5C5, .5);
}

.ant-picker-ok>button {
    background-color: @cyan;
    border-color: @cyan;
}

.ant-picker-active-bar {
    background-color: @cyan;
}

.ant-picker-month-btn {
    &:hover {
        color: @cyan !important;
    }
}

.ant-picker-year-btn {
    &:hover {
        color: @cyan !important;
    }
}

.ant-picker-decade-btn {
    &:hover {
        color: @cyan;
    }
}

.ant-upload-list-picture-container {
    .ant-upload-list-item-uploading {
        border: 1px solid @cyan;

        .ant-upload-list-item-name {
            &:hover {
                color: @cyan;
            }
        }
    }

    .ant-upload-list-item-done {
        border: 1px solid @cyan;

        .ant-upload-list-item-name {
            &:hover {
                color: @cyan;
            }
        }
    }

    .ant-upload-list-item-error {
        border: 1px solid red;

        .ant-upload-list-item-name {
            &:hover {
                color: red;
            }
        }
    }
}

.ant-upload-list-item-name {
    color: black;

    &:hover {
        color: @cyan;
    }
}

.ant-upload-list-item-file {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

a.ant-btn {
    line-height: 37px;
}

.ant-btn-default {
    background: #F3F3F7;
    color: #292929;
    border-color: rgba(#A8A8A8, 0.1);
    box-shadow: none;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont;

    &:hover {
        color: #292929;
        border-color: rgba(#A8A8A8, 0.1);
        background: darken(#F3F3F7, 3%);
        box-shadow: none;
    }

    &:focus {
        background: #F3F3F7;
        color: #292929;
        border-color: rgba(#A8A8A8, 0.1);
        box-shadow: none;
    }
}

.ant-btn-leftIcon {
    display: flex;
    align-items: center;
    justify-content: center;

    >span {
        width: auto;

        &:not(.anticon) {
            margin-left: 8px;
            margin-right: 0;
        }
    }
}

.ant-btn-rightIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;

    >span {
        width: auto;

        &:not(.anticon) {
            margin-left: 0;
            margin-right: 8px;
        }
    }

    >img {
        max-width: 18px;
    }
}

.ant-btn-outlined-secondary {
    color: @secondary-color;
    border-color: @secondary-color;

    &[disabled] {
        border-color: @border;

        &:hover {
            border-color: @border;
        }
    }

    &:hover {
        opacity: .8;
        color: @secondary-color;
        border-color: @secondary-color;
    }
}


.ant-form {
    label {
        font-size: 12px;
        color: @secondary-color;
    }

    &-item-explain.ant-form-item-explain-error {
        padding: 10px 8px 20px 0px
    }
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    height: 40px;
    font-size: 18px
}

.anticon svg {
    vertical-align: middle;
}

.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-inline>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
    height: 48px;
    line-height: 48px;
}

.ant-form-item {
    margin-bottom: 18px;
}

.ant-form-item-with-help {
    margin-bottom: 0;
}

.ant-form-item-explain {
    font-size: 12px;
}

a {
    color: @secondary-color;
}

.ant-form-item-control-input-content {
    &>input {
        height: 40px;
    }

    .ant-select {
        .ant-select-selector {
            height: 40px;
            align-items: center;
        }
    }
}

.ant-table-thead>tr>th {
    background: #fff;
    font-weight: 600;
}

.ant-tabs-tab-btn {
    font-weight: 600;
}

.ant-layout-sider-children {
    background-color: @white;
}

.ant-popconfirm {
    .ant-popover-buttons {
        min-width: 150px;

        >button {
            display: unset;
            min-width: 60px;
            height: 28px;
        }
    }
}

.ant-pagination-item-active {
    background: #fff;
    border-color: @cyan !important;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont;

    &:hover {
        background: #fff;
        border-color: @cyan;
    }
}

.ant-pagination-item-active a {
    color: @cyan !important;

    &:hover {
        color: @cyan;
    }
}

.ant-pagination-item a {
    color: black;
    font-family: 'Poppins', -apple-system, BlinkMacSystemFont;

    &:hover {
        color: @cyan;
    }
}

.ant-pagination-item-link {
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: @cyan;
    box-shadow: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid @cyan;
    box-shadow: none;

    &:hover {
        border: 1px solid @cyan;
        box-shadow: none;
    }

    &:focus {
        border: 1px solid @cyan;
        box-shadow: none;
    }
}

.ant-modal-confirm .ant-modal-confirm-btns {
    float: right;
}

::-moz-selection {
    background: @cyan;
}

::selection {
    background: @cyan;
}

.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
    margin-left: 8px;
    display: inline-flex;
    align-self: center;
}

.ant-select-clear {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.ant-input-clear-icon {
    font-size: 14px;
    display: flex;
    align-items: center;
}

.ant-btn-primary {
    color: #fff;
    border-color: @cyan;
    background: @cyan;

    &:hover {
        color: #fff;
        border-color: rgba(#3DB5C5, .75);
        background: rgba(#3DB5C5, .75);
    }

    &:focus {
        color: #fff;
        border-color: @cyan;
        background: @cyan;
    }
}

.ant-picker-ok {
    .ant-btn {
        border: 1px solid @cyan;
        background: @cyan;

        &:hover {
            border: 1px solid @cyan;
            background: @cyan;
        }
    }
}

.ant-form-item-explain-error {
    font-size: 12px;
}

.ant-empty-description {
    font-size: 0px;

    &::after {
        font-size: 14px;
        content: 'No Data Found';
    }
}

.ant-message-custom-content {
    display: inline-flex;
    align-items: center;
}

.ant-btn[disabled] {
    border: none;

    &:hover {
        border: none;
    }
}

.ant-table-thead>tr>th {
    color: #000000;
}

.ant-typography,
h3.ant-typography {
    color: #000000;
}

.ant-table-tbody>tr>td {
    color: #000000;
}

.ant-select {
    color: #000000;
}

.ant-select-selection-item>span {
    color: #000000;
}

.ant-select-arrow .anticon>svg {
    color: #000000;
}

::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #cccccc;
    border-radius: 2px;
}
::-webkit-scrollbar-thumb {
    background: #c9c9c9;
    border-radius: 2px;
}
.ant-input-group.ant-input-group-compact > *:last-child {
    height: 40px;
    font-size: 16px;
}
.ant-picker-large .ant-picker-input > input {
    color:#000000;
}
.ant-input-group.ant-input-group-compact .ant-input{
    font-size: 16px;
    color:#000000;


}
