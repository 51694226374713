@import '../../../views-dir.less';

.sidebar {
  background: white !important;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont;
  .ant-menu-item-selected {
    background-color: @cyan !important;
    border-radius: inherit !important;
  }
  .ant-menu-item {
    color: black !important;
    font-weight: 600 !important;
    margin: 0px !important;
  }
}

.dashboard-menu {
  height: 92vh !important;
  width: 100% !important;
}

.dashboard-logout {
  height: 8vh !important;
  width: 100% !important;
  padding-left: 6px !important;
}

.dashboard-logout-btn {
  color: gray !important;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont;
  display: flex !important;
  align-items: center !important;
  font-weight: 600 !important;
}

.menu-item-class {
  font-size: 24px !important;
}

@primary-color: #00AD4C;@border-radius-base: 5px;@border-color-base: #49CB5C;