.font-face(@font-family, @filepath, @font-weight, @font-style, @font-format){
    @font-face {
        font-family: @font-family;
        src: url('/src/assets/fonts/@{filepath}') format(@font-format);
        font-weight:@font-weight;
        font-style:@font-style;
    }
}

.font-face('Montserrat', 'Montserrat/Montserrat-Regular.ttf', normal, normal, 'truetype');
// .font-face('Montserrat', 'Montserrat/Montserrat-SemiBold', 400, normal);
// .font-face('Montserrat', 'Montserrat/Montserrat-Bold', bold, normal);
.font-face('Gotham', 'Gotham/Gotham-Medium.otf', normal, normal, 'opentype');
.font-face('Poppins', 'Poppins/Poppins-Regular.ttf', normal, normal, 'opentype');