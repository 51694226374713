.content-layout {
    padding: 24px !important;
    padding-top: 0 !important;
    min-height: calc(100vh - 84px) !important;
    max-height: calc(100vh - 84px) !important;
    overflow: auto !important;
}

.content-layout::-webkit-scrollbar {
    display: none;
}

.main-layout {
    height: 100vh !important;
    overflow: hidden !important;
}
@primary-color: #00AD4C;@border-radius-base: 5px;@border-color-base: #49CB5C;